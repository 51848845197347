<footer class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
  <div class="d-flex flex-column flex-sm-row flex-wrap justify-content-center gap-3 flex-grow-1 flex-basis-0 w-100 text-center"
       [class.justify-content-lg-end]="!compact"
       [class.order-lg-3]="!compact">
    <div class="d-flex justify-content-center">
      <a href="#"
         (click)="openLink(privacyPolicyLink)"
         target="_blank"
         class="link-dark link-primary-hover link-underline-opacity-0 link-underline-opacity-100-hover me-2"
         i18n="@@app.footer.privacyPolicy">Privacy Policy</a>
      <span>{{ appVersion }}</span>
    </div>
  </div>

  <p class="text-lg-start m-0 flex-grow-1 flex-basis-0"
     [class.order-lg-2]="!compact"><small>
      <span>© {{year}}</span>&nbsp;<a class="link-dark link-primary-hover"
         href="#"
         (click)="openLink('https://worlddataexchange.com')"
         target="_blank"
         i18n="@@app.hamburger.wdx"
         id="wdx">World Data Exchange B.V.</a></small></p>

  <div class="d-flex flex-column flex-sm-row justify-content-center gap-2 py-2 align-items-center"
       [class.order-lg-1]="!compact"
       [class.me-3]="!compact">
    <a href="#"
       (click)="openLink('https://worlddataexchange.com')"
       target="_blank">
      <svg style="height: 40px; width: 115px;"
           aria-labelledby="wdx">
        <use xlink:href="./assets/images/assets.svg#wdx"></use>
      </svg>
    </a>
  </div>
</footer>